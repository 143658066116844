import axios from "@/api/axios";
import store from "@/store";
import _ from "lodash";
import qs from "qs";

// 토큰 가지고 오기
const getToken = async (param, result) => {
  let finalData = {
    loginresult: {
      token: "",
    },
    message: "",
  };
  const options = {
    method: "POST",
    // url: process.env.VUE_APP_API_URL + "/login",
    url: process.env.VUE_APP_API_URL + "/login",
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      id: param.id,
      password: param.pw,
    }),
  };

  try {
    await axios(options).then((response) => {
      console.log(response.data);
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        store.dispatch("login", {
          token: finalData.loginresult.token,
          user: param.id,
          isAdmin: finalData.loginresult.fit_is_admin,
          pk: finalData.loginresult.fit_brand_pk,
        });
        store.commit("setNotification", finalData.loginresult.notification);
        if (finalData.loginresult.fit_is_admin)
          store.commit("setBrandlist", finalData.loginresult.brandlist);

        if (param.remember_yn === "y") {
          localStorage.setItem("remember_id", param.id);
          localStorage.setItem("remember_yn", "y");
        } else {
          localStorage.removeItem("remember_id");
          localStorage.removeItem("remember_yn");
        }
        result(true, response.data);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data);
      }
    });
  } catch (err) {
    console.error("getToken", err);
    result(false, err);
  }
};

const forgotPw = async (form, result) => {
  let finalData = {
    code: "",
    message: "",
  };

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/findaccount/findpwd",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      email: form.email,
      id: form.id,
    }),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("forgotPw", err);
    result(false, err);
  }
};

const forgotId = async (form, result) => {
  let finalData = {
    code: "",
    message: "",
  };
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/findaccount/findid",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      email: form.email,
    }),
  };
  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("forgotId", err);
    result(false, err);
  }
};

// 캡챠 서버체크 - 대기
const check_captcha = async (form, result) => {
  const options = {
    method: "POST",
    url: "https://www.google.com/recaptcha/api/siteverify",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      secret: "6LdJReccAAAAAGd-qvVpWzDt1_xlOYm0LbgYp-yO",
      response: form.recaptcha_token,
      remoteip: "61.101.55.231",
    }),
  };
  try {
    await axios(options).then((response) => {
      console.log(response);
    });
  } catch (err) {
    console.error("check_captcha", err);
    result(false, err);
  }
};

// 회원가입 중복체크
const availability_check = async (type, name, result) => {
  let url = "";
  let data = {};
  if (type === 1) {
    url = "ajax_player_id_check";
    data = { player_id: name };
  }
  if (type === 2) {
    url = "ajax_nickname_check";
    data = { nickname: name };
  }
  if (type === 3) {
    url = "ajax_email_check";
    data = { email: name };
  }

  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/membership/register/" + url,
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify(data),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        result(true, response.data.message, response.data.code);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message, response.data.code);
      }
    });
  } catch (err) {
    console.error("availability_check", err);
    result(false, err);
  }
};

// 회원가입
const sign_up = async (form, result) => {
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/register",
    //url: 'http://localhost/test.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      player_id: form.id,
      password: form.password,
      password_re: form.password_re,
      email: form.email,
      nickname: form.nick,
      firstname: form.firstname,
      lastname: form.lastname,
      timezone: form.timezone,
      daylighttime: form.daylighttime,
      gender: form.gender,
      hand: form.hand,
      teeheight: form.teeheight,
      fieldunit: form.fieldunit,
      greenunit: form.greenunit,
      speedunit: form.speedunit,
      emailagree: form.emailagree,
      privacy: form.privacy,
      idoverlap: form.idoverlap,
      emailoverlap: form.emailoverlap,
      nicknameoverlap: form.nicknameoverlap,
      recaptcha_token: form.recaptcha_token,
    }),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        // result(true, response.data.message)
        result(true, response.data.code, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        // result(false, response.data.message)
        result(false, response.data.code, response.data.message);
      }
    });
  } catch (err) {
    console.error("sign_up", err);
    result(false, err, 400);
  }
};

// 소셜 로그인
const socialLogin = async (param, result) => {
  let finalData = {
    loginresult: {
      token: "",
    },
    message: "",
  };
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/social_login",
    //url: 'http://localhost/test_login.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      id: param.id,
      email: param.email,
      name: param.name ? param.name : "",
      social_type: param.social,
    }),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        finalData = _.merge(finalData, response.data);
        store.dispatch("login", {
          token: finalData.loginresult[0].token,
          user: param.id,
          pk: finalData.loginresult[0].player_pk,
        });
        if (param.remember_yn === "y") {
          localStorage.setItem("remember_id", param.id);
          localStorage.setItem("remember_yn", "y");
        } else {
          localStorage.removeItem("remember_id");
          localStorage.removeItem("remember_yn");
        }
        result(true, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        result(false, response.data.message);
      }
    });
  } catch (err) {
    console.error("socialLogin", err);
    result(false, err);
  }
};

// 인증 메일 발송
const verifyEmail = async (form, result) => {
  const options = {
    method: "POST",
    url: process.env.VUE_APP_API_URL + "/register/ajax_verifyemail",
    //url: 'http://localhost/test_login.php',
    //headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    data: qs.stringify({
      player_id: form.id,
    }),
  };

  try {
    await axios(options).then((response) => {
      if (response.data.code === 200) {
        // result(true, response.data.message)
        result(true, response.data.code, response.data.message);
      } else {
        console.error(response.data.code, response.data.message);
        // result(false, response.data.message)
        result(false, response.data.code, response.data.message);
      }
    });
  } catch (err) {
    console.error("verifyEmail", err);
    result(false, err, 400);
  }
};

export default {
  getToken,
  forgotPw,
  forgotId,
  check_captcha,
  availability_check,
  sign_up,
  socialLogin,
  verifyEmail,
};
